.section-header
{
    font-size: 24px;
    text-align:center;
    padding: 32px;
    background-color: rgb(96, 171, 174);
}

.projects-section {
    background-color: rgba(210, 211, 209, 0.805); 
    min-height: 80vh;
}
  
.projects {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.home-page-title-image {
    max-width: 80%;
    max-height: 375px;
}