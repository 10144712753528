@import '../CommonStyles';

.about-me-page-section {
    padding-top: 15px;
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 250px);
}

.family-frame  {
    rotate: z -5deg;
    width: 300px;
    height: 300px;
    position: relative;
    left: 10%;
    margin: unset;
    top: 15px;
    margin-bottom: 15px;
}

img {
    width: 100%;
    height: 100%;
}

.about-me-text {
    left: 13%;
    position: relative;
    text-align: left;
    padding: 15px;
    width: 60%;
    margin-top: 20px;
}

@media (min-width: $MaxPhone) {
    .about-me-page-section {
        flex-direction: row;
    }
    .about-me-text {
        margin-top: unset;
    }
}
