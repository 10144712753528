.custom-cursor {
    position: absolute;
    background-color: darkblue;
    color: white;
    width: calc(4rem + 2vw);
    height: calc(4rem + 2vw);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    transition: 1s cubic-bezier(.15,1.1,.54,1.08), opacity 0.3s;
    p {
        text-transform: uppercase;
        font-size: calc(0.4rem + 0.4vw);
        letter-spacing: 0.08rem;
    }
}