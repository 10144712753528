.footer-container {
    width: 100%;
}

.social-media-icon {
    display: inline-block;
    width: 50px;
}

.socials {
    float: right;
    margin-right: 20px;
    display: inline-block;
    padding: 10px;
}

.current-year {
    display: inline-block;
    vertical-align: center;
    position: relative;
}