@import '../CommonStyles';

@media (max-width: $MaxPhone) {
    .project-section {
        width: 100%;
        height: 370px;
        padding-bottom: 25px;
        padding-top: 70px;
        display: flex;
        flex-direction: column-reverse;
    }
    
    .project-section > * {
        margin: 15px auto;
        display: block;
    }

    .section-video {
        width: 350px;
    }

    .section-icon {
        width: 340px;
        height: 200px;
        padding: 5px;
        float: left;
        color: white;
        background: linear-gradient(rgba(0,0,0,.5), rgba(0,0,0,.5)),
        url('../computer.png');
        background-size: cover;
        background-position: center center;
    }
    
    .section-description {
        display: inline-block;
        margin: 0 30px;
    }
    
    .section-icon:hover {
        background: linear-gradient(rgba(80, 160, 241, 0.35), rgba(0,0,0,.35)),
        url('../computer.png');
        background-size: cover;
        background-position: center center;
    }
    
    .button-section {
        & > div {
            display: inline-block;
        }
    }

    .talk-bubble {
        top: 15%;
        padding: 10px;
        display: inline-block;
        width: 100%;
        max-width: 90vw;
        height: auto;
        background-color: lightyellow;
        left: 0;
        right: 0;
        margin: auto;
    }

    .round {
        border-radius: 30px;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
    }
}

@media (min-width: $MaxPhone) {
    .project-section {
        width: 100%;
        height: 350px;
        padding: 25px;
        display: flex;

        & a {
            rotate: 3deg;
        }

        &.section-icon {
            rotate: 3deg;
        }
    }
    
    .project-section > * {
        margin: 15px;
    }
    
    .project-section:nth-of-type(even) {
        flex-direction: row;
    }
    
    .project-section:nth-of-type(even) {
        flex-direction: row-reverse;
        row-gap: 10px;
        & a {
            rotate: -3deg;
        }

        &.section-icon {
            rotate: -3deg;
        }
    }

    .section-video {
        width: 550px;
    }

    .section-icon {
        width: 550px;
        height: 295px;
        padding: 10px;
        float: left;
        color: white;
        background: linear-gradient(rgba(0,0,0,.5), rgba(0,0,0,.5)),
        url('../computer.png');
        background-size: cover;
        background-position: center center;
    }
    
    .section-description {
        display: inline-block;
        margin: 0 30px;
    }
    
    .section-icon:hover {
        background: linear-gradient(rgba(80, 160, 241, 0.35), rgba(0,0,0,.35)),
        url('../computer.png');
        background-size: cover;
        background-position: center center;
    }
    
    .button-section {
        & > div {
            display: inline-block;
        }
    }


    .talk-bubble {
        top: 15%;
        margin: 40px;
        padding: 10px;
        display: inline-block;
        position: relative;
        width: 200px;
        height: auto;
        background-color: lightyellow;
    }

    .round {
        border-radius: 30px;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
    }
    
    .talk-bubble.right-in:after {
        content: ' ';
        position: absolute;
        width: 0;
        height: 0;
        top: 38px;
        bottom: auto;
        /*These are what are different between the two*/
        left: auto;
        right: -20px;
        border: 12px solid;
        border-color: lightyellow transparent transparent lightyellow;
    }

    .talk-bubble.left-in:after{
        content: ' ';
        position: absolute;
        width: 0;
        height: 0;
        left: -20px;
        right: auto;
        top: 38px;
        bottom: auto;
        border: 12px solid;
        border-color: lightyellow lightyellow transparent transparent;
    }
}
