@import '../CommonStyles.scss';
@media (max-width: $SmallPhone) {
  .navbar-container {
    overflow: hidden;
    /*This makes the color entered able to change opacity without text being affected*/
    /* background-color: rgba(0, 0, 0, 0); */
    background-color: $DarkBlue;
    font-family: Arial, Helvetica, sans-serif;
    min-height: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .navbar-container div {
    font-size: 14px;
    color: $LightText;
    text-align: center;
    text-decoration: none;
  }
  
  .navbar-container h2 {
    color: $LightText;
    text-align: center;
    padding-top: 20px;
  }
  
  .navbar-name {
    /* float: left; */
    /* padding: 14px 24px; */
  }
  
  .main-page-swap {
    right: 0;
    left: 0;
    margin: auto;
    top: 0;
    position: absolute;
    display: inline-block;
    margin-top: 8px;
    padding-bottom: 15px;
  }
  
  .main-page-swap > div {
    display: inline-block;
    padding: 14px 10px;
  }
  
  .main-page-swap > a {
    display: inline-block;
    padding: 14px 10px;
  }
  
  .main-page-swap > .clickable {
    background-color: $DarkBlue;
    color: $LightText;
  }
  
  .main-page-swap > .clickable:hover {
    color: #282c34;
    background-color: $White;
    border-radius: 5px;
    height: 80%;
    opacity: 0.5;
  }
}

@media (min-width: $SmallPhone) and (max-width: $MaxPhone) {
  .navbar-container {
    overflow: hidden;
    /*This makes the color entered able to change opacity without text being affected*/
    /* background-color: rgba(0, 0, 0, 0); */
    background-color: $DarkBlue;
    font-family: Arial, Helvetica, sans-serif;
    min-height: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .navbar-container div {
    font-size: 16px;
    color: $LightText;
    text-align: center;
    text-decoration: none;
  }
  
  .navbar-container h2 {
    color: $LightText;
    text-align: center;
    padding-top: 20px;
  }
  
  .navbar-name {
    /* float: left; */
    /* padding: 14px 24px; */
  }
  
  .main-page-swap {
    right: 0;
    left: 0;
    margin: auto;
    top: 0;
    position: absolute;
    display: inline-block;
    margin-top: 8px;
    padding-bottom: 15px;
  }
  
  .main-page-swap > div {
    display: inline-block;
    padding: 14px 16px;
  }
  
  .main-page-swap > a {
    display: inline-block;
    padding: 14px 16px;
  }
  
  .main-page-swap > .clickable {
    background-color: $DarkBlue;
    color: $LightText;
  }
  
  .main-page-swap > .clickable:hover {
    color: #282c34;
    background-color: $White;
    border-radius: 5px;
    height: 80%;
    opacity: 0.5;
  }
}

@media (min-width: $MaxPhone) {
  .navbar-container {
    overflow: hidden;
    /*This makes the color entered able to change opacity without text being affected*/
    /* background-color: rgba(0, 0, 0, 0); */
    background-color: $DarkBlue;
    font-family: Arial, Helvetica, sans-serif;
    min-height: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .navbar-container div {
    font-size: 16px;
    color: $LightText;
    text-align: center;
    text-decoration: none;
  }
  
  .navbar-container h2 {
    color: $LightText;
    text-align: center;
  }
  
  .navbar-name {
    /* float: left; */
    /* padding: 14px 24px; */
  }
  
  .main-page-swap {
    right: 0;
    top: 0;
    position: absolute;
    display: inline-block;
    margin-top: 8px;
    margin-right: 8px;
  }
  
  .main-page-swap > div {
    display: inline-block;
    padding: 14px 16px;
  }
  
  .main-page-swap > a {
    display: inline-block;
    padding: 14px 16px;
  }
  
  .main-page-swap > .clickable {
    background-color: $DarkBlue;
    color: $LightText;
  }
  
  .main-page-swap > .clickable:hover {
    color: #282c34;
    background-color: $White;
    border-radius: 5px;
    height: 80%;
    opacity: 0.5;
  }
}