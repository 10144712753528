/* Google Fonts */
@import url(https://fonts.googleapis.com/css?family=Anonymous+Pro);

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

h1 {
  padding: 8px;
  margin: unset;
}

/* Added Stuff */

.clickable {
  text-decoration: none;
  color: white;
}

.framing {
  & > * {
      border: 15px solid rgb(134, 132, 132);
  }
}

/* End added stuff */

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
