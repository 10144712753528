@import '../CommonStyles';

.project-page-title-image {
    max-width: 80%;
}

.project-instructions {
    padding: 15px;
}

@media (max-width: $MaxPhone) {
    
    .project-page-header
    {
        text-align:center;
        padding: 32px;
        background-color: rgb(96, 171, 174);
    }
    
    .project-page-section {
        background-color: rgba(210, 211, 209, 0.805); 
        min-height: 80vh;
    }
    
    .project-page-picture {
        color: white;
    }
    
    .see-it-now {
        height: 85px;
        width: 85px;
        border-radius: 50%;
        background-color: $LightBlue;
        display: inline-block;
        margin: auto;
        text-decoration: none;
        color: $DarkText;
    
        div {
            padding: 20px;
        }
    }
    
    .see-code {
        height: 85px;
        width: 85px;
        border-radius: 50%;
        background-color: $LightBlue;
        display: inline-block;
        margin: auto;
        text-decoration: none;
        color: $DarkText;
        
        div {
            padding: 12px;
        }
    }
    
    .project-page-inner-section {
        display: flex;
        padding: 15px;
        flex-direction: column;
    }
    
    .project-page-main-image {
        padding-top: 15px;
        flex: 1;
        width: 95%;
        left: 0;
        right: 0;
        margin: auto;
    }
    
    .project-page-main-summary {
        flex: 1;
    }
    
    .project-page-button-section {
        display: flex;
    }
}

@media (min-width: $MaxPhone) {
    
    .project-page-header
    {
        text-align:center;
        padding: 32px;
        background-color: rgb(96, 171, 174);
    }
    
    .project-page-section {
        background-color: rgba(210, 211, 209, 0.805); 
        min-height: 80vh;
    }
    
    .project-page-picture {
        color: white;
    }
    
    .see-it-now {
        height: 85px;
        width: 85px;
        border-radius: 50%;
        background-color: $LightBlue;
        display: inline-block;
        margin: auto;
        text-decoration: none;
        color: $DarkText;
    
        div {
            padding: 20px;
        }
    }
    
    .see-code {
        border-radius: 15px;
        background-color: $LightBlue;
        display: inline-block;
        margin: auto;
        text-decoration: none;
        color: $White;
        
        div {
            padding: 12px;
        }
    }
    
    .project-page-inner-section {
        display: flex;
        padding: 15px;
        flex-direction: row;
    }
    
    .project-page-main-image {
        padding: 15px;
        flex: 1;
        width: 50%;
    }

    .project-page-image-link {
        width: 50%;
        display: inline-block;
        padding: 15px;
        flex: 1;
    }

    .project-page-main-image-in-link {
        width: 100%;
    }
    
    .project-page-main-summary {
        flex: 1;
    }
    
    .project-page-button-section {
        display: flex;
    }
}

@media (min-width: $Other) {
    .home-page-title-image {
        height: 375px;
        width: 890px;
    }    
}