
.typewriter {
    font-family: 'Anonymous Pro', monospace; 
}
//Type Effect Section
  .line-1 {
    position: relative;
    top: 50%;  
    width: 17.8em;
    border-right: 2px solid rgba(255,255,255,.75);
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    /* transform: translateY(50%);    */
    margin: unset; 
    font-family: 'Anonymous Pro', monospace; 
  }
  
  /* Animation */
  .anim-typewriter{
    animation: typewriter 1s steps(44) 1s 1 normal both,
             blinkTextCursor 500ms steps(44) infinite normal;
  }
  @keyframes typewriter {
    from{width: 0em;}
    to{width: 9.25em;} // This is the width that works for the word
  }
  @keyframes blinkTextCursor {
    from{border-right-color: rgba(255,255,255,.75);}
    to{border-right-color: transparent;}
  }



  // Something else
//   body {
//     background-color:#ce3635;
//     text-align: center;
//     color:#fff;
//     padding-top:10em;
//   }
  
//   * { color:#fff; text-decoration: none;}

