$White: rgb(255, 255, 255);
$LightText: $White;
$DarkText: rgb(0, 0, 0);

$LightBlue: rgb(65, 105, 225);
$DarkBlue: rgb(0, 0, 136);

/** Breakpoints */
$SmallPhone: 385px;
$MaxPhone: 640px;
$MaxTablet: 768px;
$Other: 1024px;