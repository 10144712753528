.section-header
{
    font-size: 24px;
    text-align:center;
    padding: 32px;
    background-color: rgb(96, 171, 174);
}

.experience-page-title-image {
    max-width: 60%;
    max-height: 375px;
}